import React from 'react';
import './Booking.css'; // Optional, falls Sie zusätzliche Stile anwenden möchten
import '../Styles.css'; // Relativer Pfad zur Styles.css-Datei im src-Verzeichnis
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Booking() {
  const { t } = useTranslation();

  return (
    <HelmetProvider>
       <Helmet>
        <title>{t('meta:about_title')}</title>
        <meta name="description" content={t('meta:about_description')} />
        <meta name="keywords" content={t('meta:about_keywords')} />
      </Helmet>
      <div className='booking'>
        <h1>{t('booking')}</h1>
        
      </div>
    </HelmetProvider>
  );
}

export default Booking;