import React, { useEffect } from 'react';
import './Contact.css';
import '../Styles.css';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function loadGoogleMapsScript(callback) {
  const existingScript = document.getElementById('googleMaps');

  if (!existingScript) {
    const script = document.createElement('script');
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`;
    script.id = 'googleMaps';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
}

function Contact() {
  const { t } = useTranslation();

  useEffect(() => {
    window.initMap = initMap;
    loadGoogleMapsScript(() => {
      if (window.google) {
        initMap();
      }
    });
  }, []);

  const initMap = () => {
    const mapDiv = document.getElementById('map');
    if (mapDiv) {
      const map = new window.google.maps.Map(mapDiv, {
        center: { lat: 47.5584, lng: 7.5733 },
        zoom: 12,
      });

      new window.google.maps.Marker({
        position: { lat: 47.5584, lng: 7.5733 },
        map: map,
        title: 'Body-Beautyline, Riehenstrasse 24, 4058 Basel',
      });
    }
  };

  return (
    <HelmetProvider>
       <Helmet>
        <title>{t('meta:about_title')}</title>
        <meta name="description" content={t('meta:about_description')} />
        <meta name="keywords" content={t('meta:about_keywords')} />
      </Helmet>
      <div className='contact'>
        <h1>H1 Titel Bezeichnung</h1>
        <h2>H2 Titel Bezeichnung</h2>
        <h3>H3 Titel Bezeichnung</h3>
        <h4>H4 Titel Bezeichnung</h4>
        <h5>H5 Titel Bezeichnung</h5>
        <h6>H6 Titel Bezeichnung</h6>
        <p> {t('text_test')} </p>
        <div id="map" style={{ height: '500px', width: '100%' }}></div>
      </div>
    </HelmetProvider>
  );
}

export default Contact;
