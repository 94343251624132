import React from 'react';
import './HotStone.css'; // Optional, falls Sie zusätzliche Stile anwenden möchten
import '../Styles.css'; // Relativer Pfad zur Styles.css-Datei im src-Verzeichnis
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function HotStone() {
  const { t } = useTranslation(['translation', 'hotstone']);

  const main = t('hotstone:hotstone_main', { returnObjects: true });
  const benefits = t('hotstone:hotstone_benefits', { returnObjects: true });


  const menuItems = [
    {
      treatment: t('hotstone:hotstone_pricing_treatment_1'),
      duration: t('hotstone:hotstone_pricing_duration_1'),
      abo: t('hotstone:hotstone_pricing_abo_1'),
      price: t('hotstone:hotstone_pricing_price_1'),
      description: t('hotstone:hotstone_pricing_description_1'),
    },
    {
      treatment: t('hotstone:hotstone_pricing_treatment_2'),
      duration: t('hotstone:hotstone_pricing_duration_2'),
      abo: t('hotstone:hotstone_pricing_abo_2'),
      price: t('hotstone:hotstone_pricing_price_2'),
      description: t('hotstone:hotstone_pricing_description_2'),    
    },
    {
      treatment: t('hotstone:hotstone_pricing_treatment_3'),
      duration: t('hotstone:hotstone_pricing_duration_3'),
      abo: t('hotstone:hotstone_pricing_abo_3'),
      price: t('hotstone:hotstone_pricing_price_3'),
      description: t('hotstone:hotstone_pricing_description_3'),
    },
    {
      treatment: t('hotstone:hotstone_pricing_treatment_4'),
      duration: t('hotstone:hotstone_pricing_duration_4'),
      abo: t('hotstone:hotstone_pricing_abo_4'),
      price: t('hotstone:hotstone_pricing_price_4'),
      description: t('hotstone:hotstone_pricing_description_4'),    
    },
    {
      treatment: t('hotstone:hotstone_pricing_treatment_5'),
      duration: t('hotstone:hotstone_pricing_duration_5'),
      abo: t('hotstone:hotstone_pricing_abo_5'),
      price: t('hotstone:hotstone_pricing_price_5'),
      description: t('hotstone:hotstone_pricing_description_5'),    
    },
    {
      treatment: t('hotstone:hotstone_pricing_treatment_6'),
      duration: t('hotstone:hotstone_pricing_duration_6'),
      abo: t('hotstone:hotstone_pricing_abo_6'),
      price: t('hotstone:hotstone_pricing_price_6'),
      description: t('hotstone:hotstone_pricing_description_6'),    
    },
  ]


return (
  <HelmetProvider>
    <div className="hotstone">
        <Helmet>
          <title>{t('hotstone:hotstone_meta_title')}</title>
          <meta name="description" content={t('hotstone:hotstone_meta_description')} />
          <meta name="keywords" content={t('hotstone:hotstone_meta_keywords')} />
          <link rel="canonical" href="https://www.body-beautyline.com/hot-stone" />
        </Helmet>
      <header className="header-treatment">
        <h1 className="title-treatment">{t('hotstone:hotstone_title')}</h1>
      </header>
      <main className="main-content-treatment">
        <section className="section-treatment left-treatment">
          <h3>{t('hotstone:hotstone_paragraph_titel_1')}</h3>
          <p>{t('hotstone:hotstone_paragraph_1_1')}</p>
          <h3>{t('hotstone:hotstone_paragraph_titel_2')}</h3>
          <p>{t('hotstone:hotstone_paragraph_2_1')}</p>
          <h4>{t('hotstone:hotstone_paragraph_titel_3')}</h4>
          <ul>
            {main.map((item, index) => {
              const [firstPart, ...rest] = item.split(':');
              return (
                <li key={index}>
                  <strong style={{ color: 'var(--text-color-gold)' }}>{firstPart}:</strong>{rest.join(':')}
                </li>
              );
            })}
          </ul>
          <h4>{t('hotstone:hotstone_paragraph_titel_4')}</h4>
          <ul>
            {benefits.map((item, index) => {
              const [firstPart, ...rest] = item.split(':');
              return (
                <li key={index}>
                  <strong style={{ color: 'var(--text-color-gold)' }}>{firstPart}:</strong>{rest.join(':')}
                </li>
              );
            })}
          </ul>
        </section>
        <section className="section-treatment right-treatment">
          <h3>{t('hotstone:hotstone_paragraph_titel_5')}</h3>
          <p>{t('hotstone:hotstone_paragraph_5_1')}</p>
          <h3>{t('hotstone:hotstone_paragraph_titel_6')}</h3>
          <p>{t('hotstone:hotstone_paragraph_6_1')}</p>
          <h3>{t('hotstone:hotstone_paragraph_titel_7')}</h3>
          <p>{t('hotstone:hotstone_paragraph_7_1')}</p>
          <h3>{t('hotstone:hotstone_paragraph_titel_8')}</h3>
          <p>{t('hotstone:hotstone_paragraph_8_1')}</p>
        </section>
      </main>
      <footer className="footer-treatment">
        <h1 className="title-treatment">{t('price_list')}</h1>
        <div className="container-pricelist">
          {menuItems.map((item, index) => (
            <div key={index} className="pricelist-item-treatment">
              <div className="pricelist-card-treatment">
                <p className="treatment">{item.treatment}</p>
                <div 
                  className="treatment-description" 
                  dangerouslySetInnerHTML={{ __html: item.description.replace(/\n/g, '<br>') }}
                ></div>
              </div>
              <div className="treatment-info">
                <span className="treatment-duration">{item.duration}</span>
                {item.abo && item.abo !== "" && (
                  <span className="treatment-abo">{item.abo}</span>
                )}
                <div className="treatment-divider"></div>
                <div className="treatment-price">{item.price}</div>
              </div>
            </div>
          ))}
        </div>
      </footer>
      </div>
      </HelmetProvider>
  );
}

export default HotStone;