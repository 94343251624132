import React from 'react';
import './NagelstudioSandra.css'; // Optional, falls Sie zusätzliche Stile anwenden möchten
import '../Styles.css'; // Relativer Pfad zur Styles.css-Datei im src-Verzeichnis
import { useTranslation } from 'react-i18next';
import logo from '../images/logo_sandra.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function NagelstudioSandra() {
  const { t } = useTranslation(['translation', 'sandra']);

return (
  <HelmetProvider>
    <div className="sandra">
      <Helmet>
        <title>{t('sandra:sandra_meta_title')}</title>
        <meta name="description" content={t('sandra:sandra_meta_description')} />
        <meta name="keywords" content={t('sandra:sandra_meta_keywords')} />
      </Helmet>
      <header className="header-treatment">
        <h1 className="title-treatment">{t('sandra:sandra_title')}</h1>
      </header>
      <main className="main-content-treatment">
        <section className="section-treatment left-treatment">
          <p>{t('sandra:sandra_paragraph_1_1')}</p>
          <h3>{t('sandra:sandra_paragraph_titel_2')}</h3>
          <p>{t('sandra:sandra_paragraph_2_1')}</p>
          <h3>{t('sandra:sandra_paragraph_titel_3')}</h3>
          <p>{t('sandra:sandra_paragraph_3_1')}</p>
          <h3>{t('sandra:sandra_paragraph_titel_4')}</h3>
          <p>{t('sandra:sandra_paragraph_4_1')}</p>
          <h3>{t('sandra:sandra_paragraph_titel_5')}</h3>
          <p>{t('sandra:sandra_paragraph_5_1')}</p>
          <p>{t('sandra:sandra_paragraph_5_2')}</p>
        </section>
      </main>
      <div className="sandra-logo">
        <a href="https://www.nagelstudio-baselstadt.ch" target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="Nagelstudio Sandra" className='image' />
        </a>
      </div>
      </div>
    </HelmetProvider>
  );
}

export default NagelstudioSandra;
