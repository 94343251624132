import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import '../Styles.css'; // Relativer Pfad zur Styles.css-Datei im src-Verzeichnis
import slide1 from '../images/hero_slide_4_small.jpg'; // Ersetzen Sie durch Ihre Slide-Bilder
import slide2 from '../images/hero_slide_5_small.jpg'; // Ersetzen Sie durch Ihre Slide-Bilder
import slide3 from '../images/hero_slide_6_small.jpg'; // Ersetzen Sie durch Ihre Slide-Bilder
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Home() {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();

  const slides = [
    {
      image: slide1,
      title: t('hero_title_1'),
      subtitle: t('hero_subtitle_1'),
      header: t('hero_header_1'),
      buttonText: t('hero_button_1'),
      buttonLink: '/services'  // Verlinkung zur Services-Seite
    },
    {
      image: slide2,
      title: t('hero_title_2'),
      subtitle: t('hero_subtitle_2'),
      header: t('hero_header_2'),
      buttonText: t('hero_button_2'),
      buttonLink: '/services'  // Verlinkung zur Services-Seite
    },
    {
      image: slide3,
      title: t('hero_title_3'),
      subtitle: t('hero_subtitle_3'),
      header: t('hero_header_3'),
      buttonText: t('hero_button_3'),
      buttonLink: '/services'  // Verlinkung zur Services-Seite
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{t('meta:home_title')}</title>
        <meta name="description" content={t('meta:home_description')} />
        <meta name="keywords" content={t('meta:home_keywords')} />
      </Helmet>
      <div className="homepage">
        <div className="hero-slider">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`hero-slide ${index === currentSlide ? 'active' : ''}`}
              style={{ backgroundImage: `url(${slide.image})` }}
            >
              <h3>{slide.header}</h3>
              <h2>{slide.title}</h2>
              <p>{slide.subtitle}</p>
              <button onClick={() => navigate(slide.buttonLink)}>
                <span>{slide.buttonText}</span>
              </button>
            </div>
          ))}
          <button
            className="hero-slider-arrow left"
            onClick={() => setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length)}
          >
            &#10094;
          </button>
          <button
            className="hero-slider-arrow right"
            onClick={() => setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length)}
          >
            &#10095;
          </button>
        </div>
      </div>
    </HelmetProvider>
  );
}

export default Home;
