import React, { useEffect } from 'react';
import './TopBar.css';
import { useTranslation } from 'react-i18next';
import { FaInstagram, FaPhone, FaClock, FaMapMarkedAlt, FaFacebookF, FaLinkedinIn, FaTwitter, FaYoutube } from 'react-icons/fa';
import { SiWhatsapp } from 'react-icons/si';
import './i18n';

function TopBar() {
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const topbar = document.querySelector('.TopBar');
      if (window.scrollY > 50) {
        topbar.style.backgroundColor = 'var(--background-color)';
      } else {
        topbar.style.backgroundColor = 'transparent';
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="TopBar">
      <div className="container">
        <div className="social">
          <a href="https://www.instagram.com/bodybeautyline/" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="icon" />
          </a>
          <a href="https://www.facebook.com/bodybeautyline/" target="_blank" rel="noopener noreferrer">
            <FaFacebookF className="icon" />
          </a>
          <a href="https://www.linkedin.com/in/katarina-batsilas-285b09bb/" target="_blank" rel="noopener noreferrer">
            <FaLinkedinIn className="icon" />
          </a>
          <a href="https://twitter.com/bodybeautyline" target="_blank" rel="noopener noreferrer">
            <FaTwitter className="icon" />
          </a>
          <a href="https://www.youtube.com/@bodybeautyline" target="_blank" rel="noopener noreferrer">
            <FaYoutube className="icon" />
          </a>
        </div>
        <div className="adress">
          <a href="https://www.google.com/maps/place/Kosmetikstudio+Body-Beautyline/@47.5606387,7.5957182,17z/data=!3m1!4b1!4m6!3m5!1s0x4791b9b0c94a5923:0xb6e35bba7205bc8b!8m2!3d47.5606351!4d7.5982931!16s%2Fg%2F11cm0kfnny?entry=ttu" target="_blank" rel="noopener noreferrer">
            <FaMapMarkedAlt className="icon-small" />
            <div>
              <span>Riehenstrasse 24, 4058 Basel</span>
            </div>
          </a>
        </div>
        <div className="tel">
          <FaPhone className="icon-small" />
          <div>
            <span>{t('tel_number')}</span>
          </div>
        </div>
        <div className="open">
          <FaClock className="icon-small" />
          <div>
            <span>{t('monday_to_saturday')}</span>
          </div>
        </div>
        <div className="whatsapp">
          <a href="https://api.whatsapp.com/send?phone=41796896880" target="_blank" rel="noopener noreferrer">
            <SiWhatsapp className="icon" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
