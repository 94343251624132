import React from 'react';
import './MaderoTherapie.css'; // Optional, falls Sie zusätzliche Stile anwenden möchten
import '../Styles.css'; // Relativer Pfad zur Styles.css-Datei im src-Verzeichnis
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import maderoBild1 from '../images/Madero-Therapie.png';
import maderoBild2 from '../images/Madero-Therapie-2.png';

function MaderoTherapie() {
  const { t } = useTranslation(['translation', 'madero']);

  const root = t('madero:madero_ursprung', { returnObjects: true });
  const benefits = t('madero:madero_benefits', { returnObjects: true });

  const menuItems = [
    {
      treatment: t('madero:madero_pricing_treatment_1'),
      duration: t('madero:madero_pricing_duration_1'),
      abo: t('madero:madero_pricing_abo_1'),
      price: t('madero:madero_pricing_price_1'),
      description: t('madero:madero_pricing_description_1'),
    },
    {
      treatment: t('madero:madero_pricing_treatment_2'),
      duration: t('madero:madero_pricing_duration_2'),
      abo: t('madero:madero_pricing_abo_2'),
      price: t('madero:madero_pricing_price_2'),
      description: t('madero:madero_pricing_description_2'),    
    },
    {
      treatment: t('madero:madero_pricing_treatment_3'),
      duration: t('madero:madero_pricing_duration_3'),
      abo: t('madero:madero_pricing_abo_3'),
      price: t('madero:madero_pricing_price_3'),
      description: t('madero:madero_pricing_description_3'),
    },
  ]
  
return (
  <HelmetProvider>
    <div className="madero">
    <Helmet>
      <title>{t('madero:madero_meta_title')}</title>
      <meta name="description" content={t('madero:madero_meta_description')} />
      <meta name="keywords" content={t('madero:madero_meta_keywords')} />
    </Helmet>
    <header className="header-treatment">
      <h1 className="title-treatment">{t('madero:madero_title')}</h1>
    </header>
    <main className="main-content-treatment">
      <section className="section-treatment left-treatment">
        <h3>{t('madero:madero_paragraph_titel_1')}</h3>
        <p>{t('madero:madero_paragraph_1_1')}</p>
        <h3>{t('madero:madero_paragraph_title_2')}</h3>
        <p>{t('madero:madero_paragraph_2_1')}</p>
        <h4>{t('madero:madero_paragraph_title_3')}</h4>
        <ul>
          {root.map((item, index) => {
            const [firstPart, ...rest] = item.split(':');
            return (
              <li key={index}>
                <strong style={{ color: 'var(--text-color-gold)' }}>{firstPart}:</strong>{rest.join(':')}
              </li>
            );
          })}
        </ul>
        <img src={maderoBild1} alt={t('Bild_Madero_1_alt')} className='image' />
        <h4>{t('madero:madero_paragraph_title_4')}</h4>
        <ul>
          {benefits.map((item, index) => {
            const [firstPart, ...rest] = item.split(':');
            return (
              <li key={index}>
                <strong style={{ color: 'var(--text-color-gold)' }}>{firstPart}:</strong>{rest.join(':')}
              </li>
            );
          })}
        </ul>
      </section>
      <section className="section-treatment left-treatment">
        <h3>{t('madero:madero_paragraph_title_5')}</h3>
        <p>{t('madero:madero_paragraph_5_1')}</p>
        <h3>{t('madero:madero_paragraph_title_6')}</h3>
        <p>{t('madero:madero_paragraph_6_1')}</p>
        <img src={maderoBild2} alt={t('Bild_Madero_1_alt')} className='image' />
        <h3>{t('madero:madero_paragraph_title_7')}</h3>
        <p>{t('madero:madero_paragraph_7_1')}</p>
        <h3>{t('madero:madero_paragraph_title_8')}</h3>
        <p>{t('madero:madero_paragraph_8_1')}</p>
      </section>
    </main>
    <footer className="footer-treatment">
      <h1 className="title-treatment">{t('price_list')}</h1>
      <div className="container-pricelist">
        {menuItems.map((item, index) => (
          <div key={index} className="pricelist-item-treatment">
            <div className="pricelist-card-treatment">
              <p className="treatment">{item.treatment}</p>
              <div 
                className="treatment-description" 
                dangerouslySetInnerHTML={{ __html: item.description.replace(/\n/g, '<br>') }}
              ></div>
            </div>
            <div className="treatment-info">
              <span className="treatment-duration">{item.duration}</span>
              {item.abo && item.abo !== "" && (
                <span className="treatment-abo">{item.abo}</span>
              )}
              <div className="treatment-divider"></div>
              <div className="treatment-price">{item.price}</div>
            </div>
          </div>
        ))}
      </div>
    </footer>
    </div>
  </HelmetProvider>
);
}

export default MaderoTherapie;