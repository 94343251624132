import React from 'react';
import './Wimpern.css'; // Optional, falls Sie zusätzliche Stile anwenden möchten
import '../Styles.css'; // Relativer Pfad zur Styles.css-Datei im src-Verzeichnis
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Wimpern() {
  const { t } = useTranslation(['translation', 'wimpern']);

  const menuItems = [
    {
      treatment: t('wimpern:wimpern_pricing_treatment_1'),
      duration: t('wimpern:wimpern_pricing_duration_1'),
      abo: t('wimpern:wimpern_pricing_abo_1'),
      price: t('wimpern:wimpern_pricing_price_1'),
      description: t('wimpern:wimpern_pricing_description_1'),
    },
    {
      treatment: t('wimpern:wimpern_pricing_treatment_2'),
      duration: t('wimpern:wimpern_pricing_duration_2'),
      abo: t('wimpern:wimpern_pricing_abo_2'),
      price: t('wimpern:wimpern_pricing_price_2'),
      description: t('wimpern:wimpern_pricing_description_2'),
    },
    {
      treatment: t('wimpern:wimpern_pricing_treatment_3'),
      duration: t('wimpern:wimpern_pricing_duration_3'),
      abo: t('wimpern:wimpern_pricing_abo_3'),
      price: t('wimpern:wimpern_pricing_price_3'),
      description: t('wimpern:wimpern_pricing_description_3'),
    },
    {
      treatment: t('wimpern:wimpern_pricing_treatment_4'),
      duration: t('wimpern:wimpern_pricing_duration_4'),
      abo: t('wimpern:wimpern_pricing_abo_4'),
      price: t('wimpern:wimpern_pricing_price_4'),
      description: t('wimpern:wimpern_pricing_description_4'),
    },
    {
      treatment: t('wimpern:wimpern_pricing_treatment_5'),
      duration: t('wimpern:wimpern_pricing_duration_5'),
      abo: t('wimpern:wimpern_pricing_abo_5'),
      price: t('wimpern:wimpern_pricing_price_5'),
      description: t('wimpern:wimpern_pricing_description_5'),
    },
    {
      treatment: t('wimpern:wimpern_pricing_treatment_6'),
      duration: t('wimpern:wimpern_pricing_duration_6'),
      abo: t('wimpern:wimpern_pricing_abo_6'),
      price: t('wimpern:wimpern_pricing_price_6'),
      description: t('wimpern:wimpern_pricing_description_6'),
    },
    {
      treatment: t('wimpern:wimpern_pricing_treatment_7'),
      duration: t('wimpern:wimpern_pricing_duration_7'),
      abo: t('wimpern:wimpern_pricing_abo_7'),
      price: t('wimpern:wimpern_pricing_price_7'),
      description: t('wimpern:wimpern_pricing_description_7'),
    },
    {
      treatment: t('wimpern:wimpern_pricing_treatment_8'),
      duration: t('wimpern:wimpern_pricing_duration_8'),
      abo: t('wimpern:wimpern_pricing_abo_8'),
      price: t('wimpern:wimpern_pricing_price_8'),
      description: t('wimpern:wimpern_pricing_description_8'),
    },
    {
      treatment: t('wimpern:wimpern_pricing_treatment_9'),
      duration: t('wimpern:wimpern_pricing_duration_9'),
      abo: t('wimpern:wimpern_pricing_abo_9'),
      price: t('wimpern:wimpern_pricing_price_9'),
      description: t('wimpern:wimpern_pricing_description_9'),
    },
    {
      treatment: t('wimpern:wimpern_pricing_treatment_10'),
      duration: t('wimpern:wimpern_pricing_duration_10'),
      abo: t('wimpern:wimpern_pricing_abo_10'),
      price: t('wimpern:wimpern_pricing_price_10'),
      description: t('wimpern:wimpern_pricing_description_10'),
    },
    {
      treatment: t('wimpern:wimpern_pricing_treatment_11'),
      duration: t('wimpern:wimpern_pricing_duration_11'),
      abo: t('wimpern:wimpern_pricing_abo_11'),
      price: t('wimpern:wimpern_pricing_price_11'),
      description: t('wimpern:wimpern_pricing_description_11'),
    },
    {
      treatment: t('wimpern:wimpern_pricing_treatment_12'),
      duration: t('wimpern:wimpern_pricing_duration_12'),
      abo: t('wimpern:wimpern_pricing_abo_12'),
      price: t('wimpern:wimpern_pricing_price_12'),
      description: t('wimpern:wimpern_pricing_description_12'),
    },
    {
      treatment: t('wimpern:wimpern_pricing_treatment_13'),
      duration: t('wimpern:wimpern_pricing_duration_13'),
      abo: t('wimpern:wimpern_pricing_abo_13'),
      price: t('wimpern:wimpern_pricing_price_13'),
      description: t('wimpern:wimpern_pricing_description_13'),
    },
    {
      treatment: t('wimpern:wimpern_pricing_treatment_14'),
      duration: t('wimpern:wimpern_pricing_duration_14'),
      abo: t('wimpern:wimpern_pricing_abo_14'),
      price: t('wimpern:wimpern_pricing_price_14'),
      description: t('wimpern:wimpern_pricing_description_14'),
    },
    {
      treatment: t('wimpern:wimpern_pricing_treatment_15'),
      duration: t('wimpern:wimpern_pricing_duration_15'),
      abo: t('wimpern:wimpern_pricing_abo_15'),
      price: t('wimpern:wimpern_pricing_price_15'),
      description: t('wimpern:wimpern_pricing_description_15'),
    },
    {
      treatment: t('wimpern:wimpern_pricing_treatment_16'),
      duration: t('wimpern:wimpern_pricing_duration_16'),
      abo: t('wimpern:wimpern_pricing_abo_16'),
      price: t('wimpern:wimpern_pricing_price_16'),
      description: t('wimpern:wimpern_pricing_description_16'),
    },
    {
      treatment: t('wimpern:wimpern_pricing_treatment_17'),
      duration: t('wimpern:wimpern_pricing_duration_17'),
      abo: t('wimpern:wimpern_pricing_abo_17'),
      price: t('wimpern:wimpern_pricing_price_17'),
      description: t('wimpern:wimpern_pricing_description_17'),
    },
    {
      treatment: t('wimpern:wimpern_pricing_treatment_18'),
      duration: t('wimpern:wimpern_pricing_duration_18'),
      abo: t('wimpern:wimpern_pricing_abo_18'),
      price: t('wimpern:wimpern_pricing_price_18'),
      description: t('wimpern:wimpern_pricing_description_18'),
    },
    {
      treatment: t('wimpern:wimpern_pricing_treatment_19'),
      duration: t('wimpern:wimpern_pricing_duration_19'),
      abo: t('wimpern:wimpern_pricing_abo_19'),
      price: t('wimpern:wimpern_pricing_price_19'),
      description: t('wimpern:wimpern_pricing_description_19'),
    },
  ]


  return (
    <HelmetProvider>
      <div className="wimpern">
          <Helmet>
            <title>{t('wimpern:wimpern_meta_title')}</title>
            <meta name="description" content={t('wimpern:wimpern_meta_description')} />
            <meta name="keywords" content={t('wimpern:wimpern_meta_keywords')} />
        </Helmet>
        <header className="header-treatment">
          <h1 className="title-treatment">{t('wimpern:wimpern_title')}</h1>
        </header>
        <main className="main-content-treatment">
          <section className="section-treatment left-treatment">
            <h3>{t('wimpern:wimpern_paragraph_titel_1')}</h3>
            <p>{t('wimpern:wimpern_paragraph_1_1')}</p>
            <h3>{t('wimpern:wimpern_paragraph_titel_2')}</h3>
            <p>{t('wimpern:wimpern_paragraph_2_1')}</p>
            <h3>{t('wimpern:wimpern_paragraph_titel_3')}</h3>
            <p>{t('wimpern:wimpern_paragraph_3_1')}</p>
            <h3>{t('wimpern:wimpern_paragraph_titel_4')}</h3>
            <p>{t('wimpern:wimpern_paragraph_4_1')}</p>
          </section>
          <section className="section-treatment right-treatment">
            <h3>{t('wimpern:wimpern_paragraph_titel_5')}</h3>
            <p>{t('wimpern:wimpern_paragraph_5_1')}</p>
            <h3>{t('wimpern:wimpern_paragraph_titel_6')}</h3>
            <p>{t('wimpern:wimpern_paragraph_6_1')}</p>
            <h3>{t('wimpern:wimpern_paragraph_titel_7')}</h3>
            <p>{t('wimpern:wimpern_paragraph_7_1')}</p>
          </section>
        </main>
        <footer className="footer-treatment">
          <h1 className="title-treatment">{t('price_list')}</h1>
          <div className="container-pricelist">
            {menuItems.map((item, index) => (
              <div key={index} className="pricelist-item-treatment">
                <div className="pricelist-card-treatment">
                  <p className="treatment">{item.treatment}</p>
                  <div 
                    className="treatment-description" 
                    dangerouslySetInnerHTML={{ __html: item.description.replace(/\n/g, '<br>') }}
                  ></div>
                </div>
                <div className="treatment-info">
                  {item.duration && item.duration !== "" && (
                    <span className="treatment-duration">{item.duration}</span>
                  )}
                  {item.abo && item.abo !== "" && (
                    <span className="treatment-abo">{item.abo}</span>
                  )}
                  {(item.duration === "" && item.abo === "") && (
                    <div className="treatment-divider"></div>
                  )}
                  <div className="treatment-divider"></div>
                  <div className="treatment-price">{item.price}</div>
                </div>
              </div>
            ))}
          </div>
        </footer>
      </div>
    </HelmetProvider>
  );
}

export default Wimpern;