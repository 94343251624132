import React from 'react';
import './VitaSkin.css'; // Optional, falls Sie zusätzliche Stile anwenden möchten
import '../Styles.css'; // Relativer Pfad zur Styles.css-Datei im src-Verzeichnis
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function VitaSkin() {
  const { t } = useTranslation(['translation', 'vitaskin']);

  const main = t('vitaskin:vitaskin_main', { returnObjects: true });
  const benefits = t('vitaskin:vitaskin_benefits', { returnObjects: true });

  const menuItems = [
    {
      treatment: t('vitaskin:vitaskin_pricing_treatment_1'),
      duration: t('vitaskin:vitaskin_pricing_duration_1'),
      abo: t('vitaskin:vitaskin_pricing_abo_1'),
      price: t('vitaskin:vitaskin_pricing_price_1'),
      description: t('vitaskin:vitaskin_pricing_description_1'),
    },
    {
      treatment: t('vitaskin:vitaskin_pricing_treatment_1'),
      duration: t('vitaskin:vitaskin_pricing_duration_1'),
      abo: t('vitaskin:vitaskin_pricing_abo_1'),
      price: t('vitaskin:vitaskin_pricing_price_1'),
      description: t('vitaskin:vitaskin_pricing_description_1'),
    },
  ]

  return (
    <HelmetProvider>
      <div className="vitaskin">
          <Helmet>
            <title>{t('vitaskin:vitaskin_meta_title')}</title>
            <meta name="description" content={t('vitaskin:vitaskin_meta_description')} />
            <meta name="keywords" content={t('vitaskin:vitaskin_meta_keywords')} />
        </Helmet>
        <header className="header-treatment">
          <h1 className="title-treatment">{t('vitaskin:vitaskin_title')}</h1>
        </header>
        <main className="main-content-treatment">
          <section className="section-treatment left-treatment">
            <h3>{t('vitaskin:vitaskin_paragraph_titel_1')}</h3>
            <p>{t('vitaskin:vitaskin_paragraph_1_1')}</p>  
            <h3>{t('vitaskin:vitaskin_paragraph_titel_1')}</h3>
            <p>{t('vitaskin:vitaskin_paragraph_2_1')}</p>
            <h4>{t('vitaskin:vitaskin_paragraph_titel_1')}</h4>
            <ul>
              {main.map((item, index) => {
                const [firstPart, ...rest] = item.split(':');
                return (
                  <li key={index}>
                    <strong style={{ color: 'var(--text-color-gold)' }}>{firstPart}:</strong>{rest.join(':')}
                  </li>
                );
              })}
            </ul>
            <h4>{t('vitaskin:vitaskin_paragraph_titel_4')}</h4>
            <ul>
              {benefits.map((item, index) => {
                const [firstPart, ...rest] = item.split(':');
                return (
                  <li key={index}>
                    <strong style={{ color: 'var(--text-color-gold)' }}>{firstPart}:</strong>{rest.join(':')}
                  </li>
                );
              })}
            </ul>  
          </section>
          <section className="section-treatment right-treatment">
            <h3>{t('vitaskin:vitaskin_paragraph_titel_5')}</h3>
            <p>{t('vitaskin:vitaskin_paragraph_5_1')}</p>  
            <h3>{t('vitaskin:vitaskin_paragraph_titel_6')}</h3>
            <p>{t('vitaskin:vitaskin_paragraph_6_1')}</p>  
            <h3>{t('vitaskin:vitaskin_paragraph_titel_7')}</h3>
            <p>{t('vitaskin:vitaskin_paragraph_7_1')}</p>  
            <h3>{t('vitaskin:vitaskin_paragraph_titel_8')}</h3>
            <p>{t('vitaskin:vitaskin_paragraph_8_1')}</p>
          </section>
        </main>
        <footer className="footer-treatment">
          <h1 className="title-treatment">{t('price_list')}</h1>
          <div className="container-pricelist">
            {menuItems.map((item, index) => (
              <div key={index} className="pricelist-item-treatment">
                <div className="pricelist-card-treatment">
                  <p className="treatment">{item.treatment}</p>
                  <div 
                    className="treatment-description" 
                    dangerouslySetInnerHTML={{ __html: item.description.replace(/\n/g, '<br>') }}
                  ></div>
                </div>
                <div className="treatment-info">
                  {item.duration && item.duration !== "" && (
                    <span className="treatment-duration">{item.duration}</span>
                  )}
                  {item.abo && item.abo !== "" && (
                    <span className="treatment-abo">{item.abo}</span>
                  )}
                  {(item.duration === "" && item.abo === "") && (
                    <div className="treatment-divider"></div>
                  )}
                  <div className="treatment-divider"></div>
                  <div className="treatment-price">{item.price}</div>
                </div>
              </div>
            ))}
          </div>
        </footer>
      </div>
    </HelmetProvider>
  );
}


export default VitaSkin;