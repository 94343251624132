import React from 'react';
import './PRXT33.css'; // Optional, falls Sie zusätzliche Stile anwenden möchten
import '../Styles.css'; // Relativer Pfad zur Styles.css-Datei im src-Verzeichnis
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function PRXT33() {
  const { t } = useTranslation(['translation', 'prxt33']);

  const main = t('prxt33:prxt33_main', { returnObjects: true });
  const benefits = t('prxt33:prxt33_benefits', { returnObjects: true });

  

  const menuItems = [
    {
      treatment: t('prxt33:prxt33_pricing_treatment_1'),
      duration: t('prxt33:prxt33_pricing_duration_1'),
      abo: t('prxt33:prxt33_pricing_abo_1'),
      price: t('prxt33:prxt33_pricing_price_1'),
      description: t('prxt33:prxt33_pricing_description_1'),
    },
    {
      treatment: t('prxt33:prxt33_pricing_treatment_2'),
      duration: t('prxt33:prxt33_pricing_duration_2'),
      abo: t('prxt33:prxt33_pricing_abo_2'),
      price: t('prxt33:prxt33_pricing_price_2'),
      description: t('prxt33:prxt33_pricing_description_2'),    
    },
  ]

return (
  <HelmetProvider>
    <div className="prxt33">
    <Helmet>
      <title>{t('prxt33:prxt33_meta_title')}</title>
      <meta name="description" content={t('prxt33:prxt33_meta_description')} />
      <meta name="keywords" content={t('prxt33:prxt33_meta_keywords')} />
    </Helmet>
    <header className="header-treatment">
      <h1 className="title-treatment">{t('prxt33:prxt33_title')}</h1>
    </header>
    <main className="main-content-treatment">
    <section className="section-treatment left-treatment">
      <h3>{t('prxt33:prxt33_paragraph_titel_1')}</h3>
      <p>{t('prxt33:prxt33_paragraph_1_1')}</p>  
      <h3>{t('prxt33:prxt33_paragraph_titel_2')}</h3>
      <p>{t('prxt33:prxt33_paragraph_2_1')}</p>
      <h4>{t('prxt33:prxt33_paragraph_titel_3')}</h4>
      <ul>
        {Array.isArray(main) ? (
          main.map((item, index) => {
            const [firstPart, ...rest] = item.split(':');
            return (
              <li key={index}>
                <strong style={{ color: 'var(--text-color-gold)' }}>{firstPart}:</strong>
                {rest.join(':')}
              </li>
            );
          })
        ) : (
          <li>{t('prxt33:no_data')}</li>
        )}
      </ul>
      <h4>{t('prxt33:prxt33_paragraph_title_4')}</h4>
      <ul>
        {Array.isArray(benefits) ? (
          benefits.map((item, index) => {
            const [firstPart, ...rest] = item.split(':');
            return (
              <li key={index}>
                <strong style={{ color: 'var(--text-color-gold)' }}>{firstPart}:</strong>
                {rest.join(':')}
              </li>
            );
          })
        ) : (
          <li>{t('prxt33:no_data')}</li>
        )}
      </ul>
    </section>
    <section className="section-treatment right-treatment">
      <h3>{t('prxt33:prxt33_paragraph_title_5')}</h3>
      <p>{t('prxt33:prxt33_paragraph_5_1')}</p>  
      <h3>{t('prxt33:prxt33_paragraph_title_6')}</h3>
      <p>{t('prxt33:prxt33_paragraph_6_1')}</p>  
      <h3>{t('prxt33:prxt33_paragraph_title_7')}</h3>
      <p>{t('prxt33:prxt33_paragraph_7_1')}</p>  
      <h3>{t('prxt33:prxt33_paragraph_title_8')}</h3>
      <p>{t('prxt33:prxt33_paragraph_8_1')}</p>
    </section>
    </main>
    <footer className="footer-treatment">
      <h1 className="title-treatment">{t('price_list')}</h1>
      <div className="container-pricelist">
        {menuItems.map((item, index) => (
          <div key={index} className="pricelist-item-treatment">
            <div className="pricelist-card-treatment">
              <p className="treatment">{item.treatment}</p>
              <div 
                className="treatment-description" 
                dangerouslySetInnerHTML={{ __html: item.description.replace(/\n/g, '<br>') }}
              ></div>
            </div>
            <div className="treatment-info">
              <span className="treatment-duration">{item.duration}</span>
              {item.abo && item.abo !== "" && (
                <span className="treatment-abo">{item.abo}</span>
              )}
              <div className="treatment-divider"></div>
              <div className="treatment-price">{item.price}</div>
            </div>
          </div>
        ))}
      </div>
    </footer>
    </div>
  </HelmetProvider>
);
}

export default PRXT33;