import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './NavBar.css';
import { useTranslation } from 'react-i18next';
import { FaBars, FaTimes } from 'react-icons/fa';
import { SiWhatsapp } from 'react-icons/si';
import LanguageSwitcher from './LanguageSwitcher';
import logo from './images/logo-white.png';

const NavBar = ({ showTopBar }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getNavBarClass = () => {
    if (location.pathname === '/') {
      return 'navbar-home';
    } else if (location.pathname === '/ueber-uns') {
      return 'navbar-about';
    } else if (location.pathname === '/dienstleistungen') {
      return 'navbar-services';
    } else if (location.pathname === '/kontakt') {
      return 'navbar-contact';
    } else if (location.pathname === '/termin-buchen') {
      return 'navbar-booking';
    } else if (location.pathname === '/microneedling') {
      return 'navbar-microneedling';
    } else if (location.pathname === '/datenschutz') {
      return 'navbar-datenschutz';
    }
    return '';
  };

  return (
    <div className={`navbar ${getNavBarClass()} ${scrolled ? 'scrolled' : ''} ${!showTopBar ? 'top' : ''}`}>
      <div className="navbar-left">
        <div className="navbar-logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
      </div>
      <div className="navbar-center">
        <div className={`navbar-menu ${menuOpen ? 'open' : ''}`}>
          {menuOpen && (
            <button className="menu-close" onClick={toggleMenu}>
              <FaTimes />
            </button>
          )}
          <Link to="/" onClick={toggleMenu} className={location.pathname === '/' ? 'active' : ''}>{t('home')}</Link>
          <Link to="/ueber-uns" onClick={toggleMenu} className={location.pathname === '/ueber-uns' ? 'active' : ''}>{t('about')}</Link>
          <Link to="/dienstleistungen" onClick={toggleMenu} className={location.pathname === '/dienstleistungen' ? 'active' : ''}>{t('services')}</Link>
          <Link to="/kontakt" onClick={toggleMenu} className={location.pathname === '/kontakt' ? 'active' : ''}>{t('contact')}</Link>
          <Link to="/termin-buchen" onClick={toggleMenu} className={location.pathname === '/termin-buchen' ? 'active' : ''}>{t('booking')}</Link>
        </div>
      </div>
      <div className="navbar-right">
        <LanguageSwitcher />
      </div>
      <div className="whatsapp-icon">
        <a href="https://wa.me/yourwhatsappnumber">
          <SiWhatsapp />
        </a>
      </div>
      <div className="menu-toggle" onClick={toggleMenu}>
        {menuOpen ? <FaTimes /> : <FaBars />}
      </div>
    </div>
  );
};

export default NavBar;
