import React from 'react';
import './Jordishape.css'; // Optional, falls Sie zusätzliche Stile anwenden möchten
import '../Styles.css'; // Relativer Pfad zur Styles.css-Datei im src-Verzeichnis
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Jordishape() {
  const { t } = useTranslation(['translation', 'jordishape']);

  const jordishape = t('jordishape:jordishape_jordishape', { returnObjects: true });
  const benefits = t('jordishape:jordishape_benefits', { returnObjects: true });

  const menuItems = [
    {
      treatment: t('jordishape:jordishape_pricing_treatment_1'),
      duration: t('jordishape:jordishape_pricing_duration_1'),
      abo: t('jordishape:jordishape_pricing_abo_1'),
      price: t('jordishape:jordishape_pricing_price_1'),
      description: t('jordishape:jordishape_pricing_description_1'),
    },
    {
      treatment: t('jordishape:jordishape_pricing_treatment_2'),
      duration: t('jordishape:jordishape_pricing_duration_2'),
      abo: t('jordishape:jordishape_pricing_abo_2'),
      price: t('jordishape:jordishape_pricing_price_2'),
      description: t('jordishape:jordishape_pricing_description_2'),    
    },
    {
      treatment: t('jordishape:jordishape_pricing_treatment_3'),
      duration: t('jordishape:jordishape_pricing_duration_3'),
      abo: t('jordishape:jordishape_pricing_abo_3'),
      price: t('jordishape:jordishape_pricing_price_3'),
      description: t('jordishape:jordishape_pricing_description_3'),
    },
    {
      treatment: t('jordishape:jordishape_pricing_treatment_4'),
      duration: t('jordishape:jordishape_pricing_duration_4'),
      abo: t('jordishape:jordishape_pricing_abo_4'),
      price: t('jordishape:jordishape_pricing_price_4'),
      description: t('jordishape:jordishape_pricing_description_4'),    
    },
    {
      treatment: t('jordishape:jordishape_pricing_treatment_5'),
      duration: t('jordishape:jordishape_pricing_duration_5'),
      abo: t('jordishape:jordishape_pricing_abo_5'),
      price: t('jordishape:jordishape_pricing_price_5'),
      description: t('jordishape:jordishape_pricing_description_5'),    
    },
    {
      treatment: t('jordishape:jordishape_pricing_treatment_6'),
      duration: t('jordishape:jordishape_pricing_duration_6'),
      abo: t('jordishape:jordishape_pricing_abo_6'),
      price: t('jordishape:jordishape_pricing_price_6'),
      description: t('jordishape:jordishape_pricing_description_6'),    
    },
    {
      treatment: t('jordishape:jordishape_pricing_treatment_7'),
      duration: t('jordishape:jordishape_pricing_duration_7'),
      abo: t('jordishape:jordishape_pricing_abo_7'),
      price: t('jordishape:jordishape_pricing_price_7'),
      description: t('jordishape:jordishape_pricing_description_7'),    
    },
    {
      treatment: t('jordishape:jordishape_pricing_treatment_8'),
      duration: t('jordishape:jordishape_pricing_duration_8'),
      abo: t('jordishape:jordishape_pricing_abo_8'),
      price: t('jordishape:jordishape_pricing_price_8'),
      description: t('jordishape:jordishape_pricing_description_8'),    
    },
  ]

return (
  <HelmetProvider>
    <div className="jordishape">
      <Helmet>
        <title>{t('jordishape:jordishape_meta_title')}</title>
        <meta name="description" content={t('jordishape:jordishape_meta_description')} />
        <meta name="keywords" content={t('jordishape:jordishape_meta_keywords')} />
      </Helmet>
      <header className="header-treatment">
        <h1 className="title-treatment">{t('jordishape:jordishape_title')}</h1>
      </header>
      <main className="main-content-treatment">
        <section className="section-treatment left-treatment">
          <h3>{t('jordishape:jordishape_paragraph_title_1')}</h3>
          <p>{t('jordishape:jordishape_paragraph_1_1')}</p>
          <h3>{t('jordishape:jordishape_paragraph_title_2')}</h3>
          <p>{t('jordishape:jordishape_paragraph_2_1')}</p>
          <h4>{t('jordishape:jordishape_paragraph_title_3')}</h4>
          <ul>
            {jordishape.map((item, index) => {
              const [firstPart, ...rest] = item.split(':');
              return (
                <li key={index}>
                  <strong style={{ color: 'var(--text-color-gold)' }}>{firstPart}:</strong>{rest.join(':')}
                </li>
              );
            })}
          </ul>
          <h4>{t('jordishape:jordishape_paragraph_title_4')}</h4>
          <ul>
            {benefits.map((item, index) => {
              const [firstPart, ...rest] = item.split(':');
              return (
                <li key={index}>
                  <strong style={{ color: 'var(--text-color-gold)' }}>{firstPart}:</strong>{rest.join(':')}
                </li>
              );
            })}
          </ul>
        </section>
        <section className="section-treatment right-treatment">
          <h3>{t('jordishape:jordishape_paragraph_title_5')}</h3>
          <p>{t('jordishape:jordishape_paragraph_5_1')}</p>  
          <h3>{t('jordishape:jordishape_paragraph_title_6')}</h3>
          <p>{t('jordishape:jordishape_paragraph_6_1')}</p>
          <h3>{t('jordishape:jordishape_paragraph_title_7')}</h3>
          <p>{t('jordishape:jordishape_paragraph_7_1')}</p>
          <h3>{t('jordishape:jordishape_paragraph_title_8')}</h3>
          <p>{t('jordishape:jordishape_paragraph_8_1')}</p>  
        </section>
      </main>
      <footer className="footer-treatment">
        <h1 className="title-treatment">{t('price_list')}</h1>
        <div className="container-pricelist">
          {menuItems.map((item, index) => (
            <div key={index} className="pricelist-item-treatment">
              <div className="pricelist-card-treatment">
                <p className="treatment">{item.treatment}</p>
                <div 
                  className="treatment-description" 
                  dangerouslySetInnerHTML={{ __html: item.description.replace(/\n/g, '<br>') }}
                ></div>
              </div>
              <div className="treatment-info">
                <span className="treatment-duration">{item.duration}</span>
                {item.abo && item.abo !== "" && (
                  <span className="treatment-abo">{item.abo}</span>
                )}
                <div className="treatment-divider"></div>
                <div className="treatment-price">{item.price}</div>
              </div>
            </div>
          ))}
        </div>
      </footer>
      </div>
    </HelmetProvider>
  );
}

export default Jordishape;