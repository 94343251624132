import React from 'react';
import '../Styles.css';
import './Services.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // Use useNavigate for navigation
import { Helmet, HelmetProvider } from 'react-helmet-async';

import service_1 from '../images/Behandlung_1_small.jpg'; 
import service_2 from '../images/Behandlung_2_small.jpg'; 
import service_3 from '../images/Behandlung_3_small.jpg'; 
import service_4 from '../images/Behandlung_4_small.jpg'; 
import service_5 from '../images/Behandlung_5_small.jpg'; 
import service_6 from '../images/Behandlung_1_small.jpg'; 
import service_7 from '../images/Behandlung_2_small.jpg'; 
import service_8 from '../images/Behandlung_3_small.jpg'; 
import service_9 from '../images/Behandlung_4_small.jpg'; 
import service_10 from '../images/Behandlung_5_small.jpg'; 
import service_11 from '../images/Behandlung_1_small.jpg'; 
import service_12 from '../images/Behandlung_2_small.jpg'; 

const servicesData = [
  { titleKey: 'service_title_1', descriptionKey: 'service_description_1', image: service_1, path: '/nadelepilation' },
  { titleKey: 'service_title_2', descriptionKey: 'service_description_2', image: service_2, path: '/cellulite-behandlung' },
  { titleKey: 'service_title_3', descriptionKey: 'service_description_3', image: service_3, path: '/sugaring' },
  { titleKey: 'service_title_4', descriptionKey: 'service_description_4', image: service_4, path: '/wimpern-augenbrauen' },
  { titleKey: 'service_title_5', descriptionKey: 'service_description_5', image: service_5, path: '/haarentfernung-laser' },
  { titleKey: 'service_title_6', descriptionKey: 'service_description_6', image: service_6, path: '/nagelstudio-sandra' },
  { titleKey: 'service_title_7', descriptionKey: 'service_description_7', image: service_7, path: '/microneedling' },
  { titleKey: 'service_title_8', descriptionKey: 'service_description_8', image: service_8, path: '/ultraschallbehandlung' },
  { titleKey: 'service_title_9', descriptionKey: 'service_description_9', image: service_9, path: '/gesichtsbehandlungen' },
  { titleKey: 'service_title_10', descriptionKey: 'service_description_10', image: service_10, path: '/prx-t33-revitalisierung' },
  { titleKey: 'service_title_11', descriptionKey: 'service_description_11', image: service_11, path: '/madero-therapie' },
  { titleKey: 'service_title_12', descriptionKey: 'service_description_12', image: service_12, path: '/hot-stone' },
];

const ServiceItem = ({ title, description, image, reverse, t, path }) => {
  const navigate = useNavigate(); // Use useNavigate for navigation

  const handleClick = () => {
    navigate(path); // Navigate to the specific service page
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>{t('meta:services_title')}</title>
        <meta name="description" content={t('meta:services_description')} />
        <meta name="keywords" content={t('meta:services_keywords')} />
      </Helmet>
      <div className="service-item-container">
        <div className={`service-item ${reverse ? 'reverse' : ''}`}>
          <div className="service-image">
            <img src={image} alt={title} />
          </div>
          <div className="service-details">
            <h3>{title}</h3>
            <p>{description}</p> 
            <button onClick={handleClick}>{t('button_services')}</button>
          </div>
        </div>
        <div className="separator"></div>
      </div>
    </HelmetProvider>
  );
};

function Services() {
  const { t } = useTranslation(); // Hook in die Komponente verschieben

  const services = servicesData.map(service => ({
    ...service,
    title: t(service.titleKey),
    description: t(service.descriptionKey),
  }));

  return (
    <div className="services-page">
      <div className="services-content">
        <h1 className="services-title">{t('services_title')}</h1>
        {services.map((service, index) => (
          <ServiceItem
            key={index}
            title={service.title}
            description={service.description}
            image={service.image}
            reverse={index % 2 !== 0}
            t={t} // t-Funktion an ServiceItem übergeben
            path={service.path} // Pass the path to the ServiceItem
          />
        ))}
      </div>
    </div>
  );
}

export default Services;
