import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const GoogleAnalytics = () => {
  useEffect(() => {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    function gtag(){ window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-QVC90T4GJL');
  }, []);

  return (
    <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-QVC90T4GJL"></script>
    </Helmet>
  );
};

export default GoogleAnalytics;
