import React from 'react';
import { Helmet } from 'react-helmet-async';

const LocalBusinessSchema = () => {
  const localBusinessSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Kosmetikstudio Body-Beautyline",
    "image": "https://body-beautyline.ch/wp-content/uploads/2022/08/Logo-Body-Beautyline-Weiss-Web.png",
    "@id": "https://www.body-beautyline.ch/",
    "url": "https://www.body-beautyline.ch",
    "telephone": "+41796896880",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Riehenstrasse 24",
      "addressLocality": "Basel",
      "postalCode": "4058",
      "addressCountry": "DE"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 47.56076,
      "longitude": 7.59868
    },
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday"
        ],
        "opens": "08:00",
        "closes": "19:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "Saturday",
        "opens": "08:00",
        "closes": "16:00"
      }
    ],
    "sameAs": [
      "https://www.facebook.com/bodybeautyline/",
      "https://www.instagram.com/bodybeautyline/",
      "https://www.linkedin.com/in/katarina-batsilas-285b09bb/", 
      "https://twitter.com/bodybeautyline",
      "https://www.youtube.com/@bodybeautyline"
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(localBusinessSchema)}
      </script>
    </Helmet>
  );
};

export default LocalBusinessSchema;
