import React from 'react';
import '../Styles.css'; // General styles
import './Nadelepilation.css'; // Custom styles for Nadelepilation
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Nadelepilation = () => {
  const { t } = useTranslation(['translation', 'nadelepilation']);

  const apilus = t('microneedling:microneedling_secret', { returnObjects: true });
  const benefits = t('microneedling:microneedling_benefits', { returnObjects: true });

  const menuItems = [
    {
      treatment: t('nadelepilation:nadelepilation_pricing_treatment_1'),
      duration: t('nadelepilation:nadelepilation_pricing_duration_1'),
      abo: t('nadelepilation:nadelepilation_pricing_abo_1'),
      price: t('nadelepilation:nadelepilation_pricing_price_1'),
      description: t('nadelepilation:nadelepilation_pricing_description_1'),
    },
    {
      treatment: t('nadelepilation:nadelepilation_pricing_treatment_2'),
      duration: t('nadelepilation:nadelepilation_pricing_duration_2'),
      abo: t('nadelepilation:nadelepilation_pricing_abo_2'),
      price: t('nadelepilation:nadelepilation_pricing_price_2'),
      description: t('nadelepilation:nadelepilation_pricing_description_2'),    
    },
    {
      treatment: t('nadelepilation:nadelepilation_pricing_treatment_3'),
      duration: t('nadelepilation:nadelepilation_pricing_duration_3'),
      abo: t('nadelepilation:nadelepilation_pricing_abo_3'),
      price: t('nadelepilation:nadelepilation_pricing_price_3'),
      description: t('nadelepilation:nadelepilation_pricing_description_4'),
    },
    {
      treatment: t('nadelepilation:nadelepilation_pricing_treatment_4'),
      duration: t('nadelepilation:nadelepilation_pricing_duration_4'),
      abo: t('nadelepilation:nadelepilation_pricing_abo_4'),
      price: t('nadelepilation:nadelepilation_pricing_price_4'),
      description: t('nadelepilation:nadelepilation_pricing_description_4'),    
    },
  ]


return (
  <HelmetProvider>
    <div className="nadelepilation">
      <Helmet>
        <title>{t('nadelepilation:nadelepilation_meta_title')}</title>
        <meta name="description" content={t('nadelepilation:nadelepilation_meta_description')} />
        <meta name="keywords" content={t('nadelepilation:nadelepilation_meta_keywords')} />
      </Helmet>
      <header className="header-treatment">
        <h1 className="title-treatment">{t('nadelepilation:nadelepilation_title')}</h1>
      </header>
      <main className="main-content-treatment">
        <section className="section-treatment left-treatment">
          <h3>{t('nadelepilation:nadelepilation_paragraph_titel_1')}</h3>
          <p>{t('nadelepilation:nadelepilation_paragraph_1_1')}</p>
          <h3>{t('nadelepilation:nadelepilation_paragraph_titel_2')}</h3>
          <p>{t('nadelepilation:nadelepilation_paragraph_2_1')}</p>
          <h4>{t('nadelepilation:nadelepilation_paragraph_titel_3')}</h4>
          <ul>
            {apilus.map((item, index) => {
              const [firstPart, ...rest] = item.split(':');
              return (
                <li key={index}>
                  <strong style={{ color: 'var(--text-color-gold)' }}>{firstPart}:</strong>{rest.join(':')}
                </li>
              );
            })}
          </ul>
          <h4>{t('nadelepilation:nadelepilation_paragraph_titel_4')}</h4>
          <ul>
            {benefits.map((item, index) => {
              const [firstPart, ...rest] = item.split(':');
              return (
                <li key={index}>
                  <strong style={{ color: 'var(--text-color-gold)' }}>{firstPart}:</strong>{rest.join(':')}
                </li>
              );
            })}
          </ul>
        </section>
        <section className="section-treatment right-treatment">
          <h3>{t('nadelepilation:nadelepilation_paragraph_titel_5')}</h3>
          <p>{t('nadelepilation:nadelepilation_paragraph_5_1')}</p>
          <h3>{t('nadelepilation:nadelepilation_paragraph_titel_6')}</h3>
          <p>{t('nadelepilation:nadelepilation_paragraph_6_1')}</p>
          <h3>{t('nadelepilation:nadelepilation_paragraph_titel_7')}</h3>
          <p>{t('nadelepilation:nadelepilation_paragraph_7_1')}</p>
          <h3>{t('nadelepilation:nadelepilation_paragraph_titel_8')}</h3>
          <p>{t('nadelepilation:nadelepilation_paragraph_8_1')}</p>
        </section>
      </main>
      <footer className="footer-treatment">
        <h1 className="title-treatment">{t('price_list')}</h1>
        <div className="container-pricelist">
          {menuItems.map((item, index) => (
            <div key={index} className="pricelist-item-treatment">
              <div className="pricelist-card-treatment">
                <p className="treatment">{item.treatment}</p>
                <div className="treatment-description">{item.description}</div>
              </div>
              <div className="treatment-info">
                <span className="treatment-duration">{item.duration}</span>
                {item.abo && item.abo !== "" && (
                  <span className="treatment-abo">{item.abo}</span>
                )}
                <div className="treatment-divider"></div>
                <div className="treatment-price">{item.price}</div>
              </div>
            </div>
          ))}
        </div>
      </footer>
      </div>
    </HelmetProvider>
  );
}

export default Nadelepilation;
