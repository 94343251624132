import React from 'react';
import { Helmet } from 'react-helmet-async';

const TwitterCards = ({ title, description, image, url }) => {
  return (
    <Helmet>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@bodybeautyline" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:url" content={url} />
    </Helmet>
  );
};

export default TwitterCards;
