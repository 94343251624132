import React from 'react';
import './Datenschutz.css'; // Optional, falls Sie zusätzliche Stile anwenden möchten
import '../Styles.css'; // Relativer Pfad zur Styles.css-Datei im src-Verzeichnis
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Datenschutz() {
  const { t } = useTranslation();

  return (
    <HelmetProvider>
       <Helmet>
        <title>{t('meta:about_title')}</title>
        <meta name="description" content={t('meta:about_description')} />
        <meta name="keywords" content={t('meta:about_keywords')} />
      </Helmet>
      <div className="content_datenschutz">
          <h3>{t('datenschutz_title_1')}</h3>
          <p>{t('datenschutz_paragraph_titel_1_1')}</p>
          <p>{t('datenschutz_paragraph_titel_1_2')}</p>
          <p>{t('datenschutz_paragraph_titel_1_3')}</p>
          <h3>{t('datenschutz_title_2')}</h3>
          <p>{t('datenschutz_paragraph_titel_2_1')}</p>
          <h3>{t('datenschutz_title_3')}</h3>
          <p>{t('datenschutz_paragraph_titel_3_1')}</p>
          <p>{t('datenschutz_paragraph_titel_3_2')}</p>
          <p>{t('datenschutz_paragraph_titel_3_3')}</p>
          <h3>{t('datenschutz_title_4')}</h3>
          <p>{t('datenschutz_paragraph_titel_4_1')}</p>
          <h3>{t('datenschutz_title_5')}</h3>
          <p>{t('datenschutz_paragraph_titel_5_1')}</p>
          <p>{t('datenschutz_paragraph_titel_5_2')}</p>
          <p>{t('datenschutz_paragraph_titel_5_3')}</p>
          <h3>{t('datenschutz_title_6')}</h3>
          <p>{t('datenschutz_paragraph_titel_6_1')}</p>
          <h3>{t('datenschutz_title_7')}</h3>
          <p>{t('datenschutz_paragraph_titel_7_1')}</p>
          <h3>{t('datenschutz_title_8')}</h3>
          <p>{t('datenschutz_paragraph_titel_8_1')}</p>
          <p>{t('datenschutz_paragraph_titel_8_2')}</p>
          <h3>{t('datenschutz_title_9')}</h3>
          <p>{t('datenschutz_paragraph_titel_9_1')}</p>
          <h3>{t('datenschutz_title_10')}</h3>
          <p>{t('datenschutz_paragraph_titel_10_1')}</p>
      </div>
    </HelmetProvider>
  );
}

export default Datenschutz;