import React from 'react';
import './HaarentfernungLaser.css'; // Optional, falls Sie zusätzliche Stile anwenden möchten
import '../Styles.css'; // Relativer Pfad zur Styles.css-Datei im src-Verzeichnis
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';


function HaarentfernungLaser() {
  const { t } = useTranslation(['translation', 'haarentfernungLaser']);

  const prime = t('haarentfernungLaser:haarentfernung_laser_prime', { returnObjects: true });
  const benefits = t('haarentfernungLaser:haarentfernung_laser_benefits', { returnObjects: true });

  const menuItems = [
    {
      treatment: t('haarentfernungLaser:haarentfernung_laser_pricing_treatment_1'),
      duration: t('haarentfernungLaser:haarentfernung_laser_pricing_duration_1'),
      abo: t('haarentfernungLaser:haarentfernung_laser_pricing_abo_1'),
      price: t('haarentfernungLaser:haarentfernung_laser_pricing_price_1'),
      description: t('haarentfernungLaser:haarentfernung_laser_pricing_description_1'),
    },
    {
      treatment: t('haarentfernungLaser:haarentfernung_laser_pricing_treatment_2'),
      duration: t('haarentfernungLaser:haarentfernung_laser_pricing_duration_2'),
      abo: t('haarentfernungLaser:haarentfernung_laser_pricing_abo_2'),
      price: t('haarentfernungLaser:haarentfernung_laser_pricing_price_2'),
      description: t('haarentfernungLaser:haarentfernung_laser_pricing_description_2'),    
    },
    {
      treatment: t('haarentfernungLaser:haarentfernung_laser_pricing_treatment_3'),
      duration: t('haarentfernungLaser:haarentfernung_laser_pricing_duration_3'),
      abo: t('haarentfernungLaser:haarentfernung_laser_pricing_abo_3'),
      price: t('haarentfernungLaser:haarentfernung_laser_pricing_price_3'),
      description: t('haarentfernungLaser:haarentfernung_laser_pricing_description_3'),
    },
    {
      treatment: t('haarentfernungLaser:haarentfernung_laser_pricing_treatment_4'),
      duration: t('haarentfernungLaser:haarentfernung_laser_pricing_duration_4'),
      abo: t('haarentfernungLaser:haarentfernung_laser_pricing_abo_4'),
      price: t('haarentfernungLaser:haarentfernung_laser_pricing_price_4'),
      description: t('haarentfernungLaser:haarentfernung_laser_pricing_description_4'),    
    },
    {
      treatment: t('haarentfernungLaser:haarentfernung_laser_pricing_treatment_5'),
      duration: t('haarentfernungLaser:haarentfernung_laser_pricing_duration_5'),
      abo: t('haarentfernungLaser:haarentfernung_laser_pricing_abo_5'),
      price: t('haarentfernungLaser:haarentfernung_laser_pricing_price_5'),
      description: t('haarentfernungLaser:haarentfernung_laser_pricing_description_5'),    
    },
    {
      treatment: t('haarentfernungLaser:haarentfernung_laser_pricing_treatment_6'),
      duration: t('haarentfernungLaser:haarentfernung_laser_pricing_duration_6'),
      abo: t('haarentfernungLaser:haarentfernung_laser_pricing_abo_6'),
      price: t('haarentfernungLaser:haarentfernung_laser_pricing_price_6'),
      description: t('haarentfernungLaser:haarentfernung_laser_pricing_description_6'),    
    },
    {
      treatment: t('haarentfernungLaser:haarentfernung_laser_pricing_treatment_7'),
      duration: t('haarentfernungLaser:haarentfernung_laser_pricing_duration_7'),
      abo: t('haarentfernungLaser:haarentfernung_laser_pricing_abo_7'),
      price: t('haarentfernungLaser:haarentfernung_laser_pricing_price_7'),
      description: t('haarentfernungLaser:haarentfernung_laser_pricing_description_7'),    
    },
    {
      treatment: t('haarentfernungLaser:haarentfernung_laser_pricing_treatment_8'),
      duration: t('haarentfernungLaser:haarentfernung_laser_pricing_duration_8'),
      abo: t('haarentfernungLaser:haarentfernung_laser_pricing_abo_8'),
      price: t('haarentfernungLaser:haarentfernung_laser_pricing_price_8'),
      description: t('haarentfernungLaser:haarentfernung_laser_pricing_description_8'),    
    },
    {
      treatment: t('haarentfernungLaser:haarentfernung_laser_pricing_treatment_9'),
      duration: t('haarentfernungLaser:haarentfernung_laser_pricing_duration_9'),
      abo: t('haarentfernungLaser:haarentfernung_laser_pricing_abo_9'),
      price: t('haarentfernungLaser:haarentfernung_laser_pricing_price_9'),
      description: t('haarentfernungLaser:haarentfernung_laser_pricing_description_9'),    
    },
    {
      treatment: t('haarentfernungLaser:haarentfernung_laser_pricing_treatment_10'),
      duration: t('haarentfernungLaser:haarentfernung_laser_pricing_duration_10'),
      abo: t('haarentfernungLaser:haarentfernung_laser_pricing_abo_10'),
      price: t('haarentfernungLaser:haarentfernung_laser_pricing_price_10'),
      description: t('haarentfernungLaser:haarentfernung_laser_pricing_description_10'),    
    },
    {
      treatment: t('haarentfernungLaser:haarentfernung_laser_pricing_treatment_11'),
      duration: t('haarentfernungLaser:haarentfernung_laser_pricing_duration_11'),
      abo: t('haarentfernungLaser:haarentfernung_laser_pricing_abo_11'),
      price: t('haarentfernungLaser:haarentfernung_laser_pricing_price_11'),
      description: t('haarentfernungLaser:haarentfernung_laser_pricing_description_11'),    
    },
    {
      treatment: t('haarentfernungLaser:haarentfernung_laser_pricing_treatment_12'),
      duration: t('haarentfernungLaser:haarentfernung_laser_pricing_duration_12'),
      abo: t('haarentfernungLaser:haarentfernung_laser_pricing_abo_12'),
      price: t('haarentfernungLaser:haarentfernung_laser_pricing_price_12'),
      description: t('haarentfernungLaser:haarentfernung_laser_pricing_description_12'),    
    },
    {
      treatment: t('haarentfernungLaser:haarentfernung_laser_pricing_treatment_13'),
      duration: t('haarentfernungLaser:haarentfernung_laser_pricing_duration_13'),
      abo: t('haarentfernungLaser:haarentfernung_laser_pricing_abo_13'),
      price: t('haarentfernungLaser:haarentfernung_laser_pricing_price_13'),
      description: t('haarentfernungLaser:haarentfernung_laser_pricing_description_13'),    
    },
    {
      treatment: t('haarentfernungLaser:haarentfernung_laser_pricing_treatment_14'),
      duration: t('haarentfernungLaser:haarentfernung_laser_pricing_duration_14'),
      abo: t('haarentfernungLaser:haarentfernung_laser_pricing_abo_14'),
      price: t('haarentfernungLaser:haarentfernung_laser_pricing_price_14'),
      description: t('haarentfernungLaser:haarentfernung_laser_pricing_description_14'),    
    },
    {
      treatment: t('haarentfernungLaser:haarentfernung_laser_pricing_treatment_15'),
      duration: t('haarentfernungLaser:haarentfernung_laser_pricing_duration_15'),
      abo: t('haarentfernungLaser:haarentfernung_laser_pricing_abo_15'),
      price: t('haarentfernungLaser:haarentfernung_laser_pricing_price_15'),
      description: t('haarentfernungLaser:haarentfernung_laser_pricing_description_15'),    
    },
    {
      treatment: t('haarentfernungLaser:haarentfernung_laser_pricing_treatment_16'),
      duration: t('haarentfernungLaser:haarentfernung_laser_pricing_duration_16'),
      abo: t('haarentfernungLaser:haarentfernung_laser_pricing_abo_16'),
      price: t('haarentfernungLaser:haarentfernung_laser_pricing_price_16'),
      description: t('haarentfernungLaser:haarentfernung_laser_pricing_description_16'),    
    },
    {
      treatment: t('haarentfernungLaser:haarentfernung_laser_pricing_treatment_17'),
      duration: t('haarentfernungLaser:haarentfernung_laser_pricing_duration_17'),
      abo: t('haarentfernungLaser:haarentfernung_laser_pricing_abo_17'),
      price: t('haarentfernungLaser:haarentfernung_laser_pricing_price_17'),
      description: t('haarentfernungLaser:haarentfernung_laser_pricing_description_17'),    
    },
  ]


return (
  <HelmetProvider>
    <div className="haarentfernung">
      <Helmet>
        <title>{t('haarentfernungLaser:haarentfernung_meta_title')}</title>
        <meta name="description" content={t('haarentfernungLaser:haarentfernung_meta_description')} />
        <meta name="keywords" content={t('haarentfernungLaser:haarentfernung_meta_keywords')} />
      </Helmet>
      <header className="header-treatment">
        <h1 className="title-treatment">{t('haarentfernungLaser:haarentfernung_laser_title')}</h1>
      </header>
      <main className="main-content-treatment">
        <section className="section-treatment left-treatment">
          <h3>{t('haarentfernungLaser:haarentfernung_laser_paragraph_titel_1')}</h3>
          <p>{t('haarentfernungLaser:haarentfernung_laser_paragraph_1_1')}</p>
          <h3>{t('haarentfernungLaser:haarentfernung_laser_paragraph_titel_2')}</h3>
          <p>{t('haarentfernungLaser:haarentfernung_laser_paragraph_2_1')}</p>
          <h4>{t('haarentfernungLaser:haarentfernung_laser_paragraph_titel_3')}</h4>
          <ul>
            {prime.map((item, index) => {
              const [firstPart, ...rest] = item.split(':');
              return (
                <li key={index}>
                  <strong style={{ color: 'var(--text-color-gold)' }}>{firstPart}:</strong>{rest.join(':')}
                </li>
              );
            })}
          </ul>
          <h4>{t('haarentfernungLaser:haarentfernung_laser_paragraph_titel_4')}</h4>
          <ul>
            {benefits.map((item, index) => {
              const [firstPart, ...rest] = item.split(':');
              return (
                <li key={index}>
                  <strong style={{ color: 'var(--text-color-gold)' }}>{firstPart}:</strong>{rest.join(':')}
                </li>
              );
            })}
          </ul>
        </section>
        <section className="section-treatment right-treatment">
          <h3>{t('haarentfernungLaser:haarentfernung_laser_paragraph_titel_5')}</h3>
          <p>{t('haarentfernungLaser:haarentfernung_laser_paragraph_5_1')}</p>
          <h3>{t('haarentfernungLaser:haarentfernung_laser_paragraph_titel_6')}</h3>
          <p>{t('haarentfernungLaser:haarentfernung_laser_paragraph_6_1')}</p>
          <h3>{t('haarentfernungLaser:haarentfernung_laser_paragraph_titel_7')}</h3>
          <p>{t('haarentfernungLaser:haarentfernung_laser_paragraph_7_1')}</p>
          <h3>{t('haarentfernungLaser:haarentfernung_laser_paragraph_titel_8')}</h3>
          <p>{t('haarentfernungLaser:haarentfernung_laser_paragraph_8_1')}</p>
        </section>
      </main>    
      <footer className="footer-treatment">
        <h1 className="title-treatment">{t('price_list')}</h1>
        <div className="container-pricelist">
          {menuItems.map((item, index) => (
            <div key={index} className="pricelist-item-treatment">
              <div className="pricelist-card-treatment">
                <p className="treatment">{item.treatment}</p>
                <div 
                  className="treatment-description" 
                  dangerouslySetInnerHTML={{ __html: item.description.replace(/\n/g, '<br>') }}
                ></div>
              </div>
              <div className="treatment-info">
                {item.duration && item.duration !== "" && (
                  <span className="treatment-duration">{item.duration}</span>
                )}
                {item.abo && item.abo !== "" && (
                  <span className="treatment-abo">{item.abo}</span>
                )}
                <div className="treatment-divider"></div>
                <div className="treatment-price">{item.price}</div>
              </div>
            </div>
          ))}
        </div>
      </footer>
      </div>
    </HelmetProvider>
  );
}

export default HaarentfernungLaser;