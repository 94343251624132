import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './Styles.css';
import './Styles-Background.css';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import initFacebookPixel from './initFacebookPixel';

/* Navigation Pages */
import NavBar from './NavBar';
import TopBar from './TopBar';
import TwitterCards from './TwitterCards';

/* Main Pages */
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Booking from './pages/Booking';
import Datenschutz from './pages/Datenschutz';
import Impressum from './pages/Impressum';

/* Services Pages */
import Gesichtsbehandlungen from './services/Gesichtsbehandlungen';
import HaarentfernungLaser from './services/HaarentfernungLaser';
import HotStone from './services/HotStone';
import Jordishape from './services/Jordishape';
import MaderoTherapie from './services/MaderoTherapie';
import Microneedling from './services/Microneedling';
import Nadelepilation from './services/Nadelepilation';
import NagelstudioSandra from './services/NagelstudioSandra';
import PRXT33 from './services/PRXT33';
import Sugaring from './services/Sugaring';
import VitaSkin from './services/VitaSkin';
import Wimpern from './services/Wimpern';

/* Local Business Schema */
import LocalBusinessSchema from './LocalBusinessSchema';

/* Google Analytics */
import GoogleAnalytics from './GoogleAnalytics';

function App() {
  const { t } = useTranslation();

  const [showTopBar, setShowTopBar] = useState(true);

  useEffect(() => {
    initFacebookPixel(); // Facebook Pixel initialisieren
  }, []);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY && window.scrollY > 100) {
        setShowTopBar(false);
      } else {
        setShowTopBar(true);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.title = "Body-Beautyline";
  }, []);

  return (
    <HelmetProvider>
      <div className="App">
        <Helmet>
          <title>{t('meta:home_title')}</title>
          <meta name="description" content={t('meta:home_description')} />
          <meta name="keywords" content={t('meta:home_keywords')} />
        </Helmet>
        <GoogleAnalytics /> {/* Google Analytics einfügen */}
        <LocalBusinessSchema /> {/* Local Business Schema einfügen */}
        <TwitterCards 
          title={t('meta:twitter_card_title')}
          description={t('meta:twitter_card_description')}
          image="/logo512.png"
          url="https://www.body-beautyline.ch"
        />
        <Router>
          <DynamicBackground>
            <TopBar className={showTopBar ? '' : 'hidden'} />
            <NavBar showTopBar={showTopBar} />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/ueber-uns" element={<About />} />
              <Route path="/dienstleistungen" element={<Services />} />
              <Route path="/kontakt" element={<Contact />} />
              <Route path="/termin-buchen" element={<Booking />} />
              <Route path="/gesichtsbehandlungen" element={<Gesichtsbehandlungen />} />
              <Route path="/haarentfernung-laser" element={<HaarentfernungLaser />} />
              <Route path="/hot-stone" element={<HotStone />} />
              <Route path="/cellulite-behandlung" element={<Jordishape />} />
              <Route path="/madero-therapie" element={<MaderoTherapie />} />
              <Route path="/microneedling" element={<Microneedling />} />
              <Route path="/nadelepilation" element={<Nadelepilation />} />
              <Route path="/nagelstudio-sandra" element={<NagelstudioSandra />} />
              <Route path="/prx-t33-revitalisierung" element={<PRXT33 />} />
              <Route path="/sugaring" element={<Sugaring />} />
              <Route path="/ultraschallbehandlung" element={<VitaSkin />} />
              <Route path="/wimpern-augenbrauen" element={<Wimpern />} />
              <Route path="/datenschutz" element={<Datenschutz />} />
              <Route path="/impressum" element={<Impressum />} />
            </Routes>
          </DynamicBackground>
        </Router>
      </div>
    </HelmetProvider>
  );
}

function DynamicBackground({ children }) {
  const location = useLocation();
  let backgroundClass = '';

  switch (location.pathname) {
    case '/':
      backgroundClass = 'home-background';
      break;
    case '/ueber-uns':
      backgroundClass = 'about-background';
      break;
    case '/dienstleistungen':
      backgroundClass = 'services-background';
      break;
    case '/kontakt':
      backgroundClass = 'contact-background';
      break;
    case '/termin-buchen':
      backgroundClass = 'booking-background';
      break;
    default:
      backgroundClass = 'default-background';
      break;
  }

  return <div className={backgroundClass}>{children}</div>;
}

export default App;
