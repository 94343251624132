import React from 'react';
import './Impressum.css'; // Optional, falls Sie zusätzliche Stile anwenden möchten
import '../Styles.css'; // Relativer Pfad zur Styles.css-Datei im src-Verzeichnis
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Impressum() {
  const { t } = useTranslation();

  return (
    <HelmetProvider>
        <Helmet>
            <title>{t('meta:impressum_title')}</title>
            <meta name="description" content={t('meta:impressum_description')} />
            <meta name="keywords" content={t('meta:impressum_keywords')} />
        </Helmet>
        <div className="content_datenschutz">
            <h3>{t('impressum_title_1')}</h3>
            <h4>{t('impressum_subtitle_1')}</h4>
            <p>{t('impressum_paragraph_titel_1_1').split('\n').map((line, index) => (
                <React.Fragment key={index}>
                {line}
                <br />
                </React.Fragment>
            ))}</p>
            <h4>{t('impressum_subtitle_2')}</h4>
            <p>{t('impressum_paragraph_titel_2_1').split('\n').map((line, index) => (
                <React.Fragment key={index}>
                {line}
                <br />
                </React.Fragment>
            ))}</p>
            <h4>{t('impressum_subtitle_3')}</h4>
            <p>{t('impressum_paragraph_titel_3_1').split('\n').map((line, index) => (
                <React.Fragment key={index}>
                {line}
                <br />
                </React.Fragment>
            ))}</p>
            <h4>{t('impressum_subtitle_4')}</h4>
            <p>{t('impressum_paragraph_titel_4_1')}</p>
            <h4>{t('impressum_subtitle_5')}</h4>
            <p>{t('impressum_paragraph_titel_5_1').split('\n').map((line, index) => (
                <React.Fragment key={index}>
                {line}
                <br />
                </React.Fragment>
            ))}</p>
            <h4>{t('impressum_subtitle_6_1')}</h4>
            <h4>{t('impressum_subtitle_6_2')}</h4>
            <p>{t('impressum_paragraph_titel_6_1')}</p>
            <p>{t('impressum_paragraph_titel_6_1')}</p>
            <h4>{t('impressum_subtitle_7')}</h4>
            <p>{t('impressum_paragraph_titel_7_1')}</p>
            <p>{t('impressum_paragraph_titel_7_2')}</p>
            <h4>{t('impressum_subtitle_8')}</h4>
            <p>{t('impressum_paragraph_titel_8_1')}</p>
            <p>{t('impressum_paragraph_titel_8_2')}</p>
        </div>
    </HelmetProvider>
  );
}

export default Impressum;